.Tick {
    display: flex;
    flex-direction: column;

    .numbers {
        display: flex;
        flex-direction: row;

        .number {
            background-color: rgba(255, 255, 255, 0.9);
            width: 36px;
            height: 72px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #8076F7;
            font-size: 32px;
            margin-right: 2px;

            &:last-child {
                margin-left: 2px;
                margin-right: 0;
            }
        }
    }

    .title {
        color: rgba(255, 255, 255, 0.9);
        text-align: left;
        font-size: 16px;
    }
}