@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: black;

  .App {
    text-align: center;
    background-color: black;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: stretch;

    .Screen {
      padding: 24px;
      width: 100%;
      max-width: 500px;
      display: grid;
      grid-template-rows: auto 1fr auto;
      gap: 24px;
      justify-content: stretch;
      align-items: center;

      &.Home {
        .logo {
          justify-self: center;
          width: calc(100% - 96px);
        }

        .content {
          p {
            margin: 16px 0 24px 0;
            color: white;
          }

          input[type=button] {
            background: rgba(255, 255, 255, 0.9);
            text-transform: lowercase;
            color: #8076F7;
            padding: 10px 26px;
            font-size: 18px;
            font-weight: 600;
            border-radius: 8px;
            border: none;
            cursor: pointer;
            -webkit-appearance: none;
          }

          a {
            background: rgba(255, 255, 255, 0.9);
            text-transform: lowercase;
            color: #8076F7;
            padding: 10px 26px;
            font-size: 18px;
            font-weight: 600;
            border-radius: 8px;
            border: none;
            cursor: pointer;
            -webkit-appearance: none;
            text-decoration: none;
          }
        }

        .ads-container {
          width: 100%;
          height: 70px;
          background-color: #67c0f9;

          .adsbygoogle {
            display: block;
            height: 70px !important;
            width: 100% !important;
          }
        }
      }
    }
  }
}