@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Timer{display:flex;flex-direction:column;background:#61cff9;background:linear-gradient(135deg, #61cff9 0%, #904af6 100%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#61cff9", endColorstr="#904af6", GradientType=1);border-radius:24px;padding:24px;justify-content:stretch;align-items:stretch}.Timer h1{text-align:center;font-size:24px;font-weight:500;color:rgba(255,255,255,0.9);margin-bottom:16px}.Timer .content{display:flex;flex-direction:row;justify-content:space-between;max-width:320px;width:100%;margin:0 auto}.Timer .content .colon{width:4px;height:24px;position:relative;margin-top:24px}.Timer .content .colon:before{content:" ";position:absolute;top:0;left:0;right:0;width:4px;height:4px;background-color:rgba(255,255,255,0.9);border-radius:500px}.Timer .content .colon:after{content:" ";position:absolute;bottom:0;left:0;right:0;width:4px;height:4px;background-color:rgba(255,255,255,0.9);border-radius:500px}

.Tick{display:flex;flex-direction:column}.Tick .numbers{display:flex;flex-direction:row}.Tick .numbers .number{background-color:rgba(255,255,255,0.9);width:36px;height:72px;border-radius:8px;display:flex;justify-content:center;align-items:center;color:#8076F7;font-size:32px;margin-right:2px}.Tick .numbers .number:last-child{margin-left:2px;margin-right:0}.Tick .title{color:rgba(255,255,255,0.9);text-align:left;font-size:16px}

*{box-sizing:border-box;margin:0;padding:0;font-family:"Poppins", sans-serif}body{background-color:black}body .App{text-align:center;background-color:black;height:100vh;width:100vw;display:flex;justify-content:center;align-items:stretch}body .App .Screen{padding:24px;width:100%;max-width:500px;display:grid;grid-template-rows:auto 1fr auto;grid-gap:24px;gap:24px;justify-content:stretch;align-items:center}body .App .Screen.Home .logo{justify-self:center;width:calc(100% - 96px)}body .App .Screen.Home .content p{margin:16px 0 24px 0;color:white}body .App .Screen.Home .content input[type=button]{background:rgba(255,255,255,0.9);text-transform:lowercase;color:#8076F7;padding:10px 26px;font-size:18px;font-weight:600;border-radius:8px;border:none;cursor:pointer;-webkit-appearance:none}body .App .Screen.Home .content a{background:rgba(255,255,255,0.9);text-transform:lowercase;color:#8076F7;padding:10px 26px;font-size:18px;font-weight:600;border-radius:8px;border:none;cursor:pointer;-webkit-appearance:none;text-decoration:none}body .App .Screen.Home .ads-container{width:100%;height:70px;background-color:#67c0f9}body .App .Screen.Home .ads-container .adsbygoogle{display:block;height:70px !important;width:100% !important}

